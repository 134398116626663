.about-row {
  display: flex;
  flex-direction: row;
}

.box1 {
  opacity: 0;
  will-change: transform, opacity;
}

#about-text {
  display: flex;
  color: white;
  width: 50%;
  white-space: pre-wrap;
  align-items: center;
  font-size: 1.1rem;
  line-height: 1.75rem;
}

#about-img-box {
  display: flex;
  width: 25%;
  min-width: 250px;
  justify-content: flex-end;
  align-items: center;
  margin-left: 20%;
}

#about-img {
  display: flex;
  width: 100%;
  border-radius: 0.325rem;
}

@media (max-width: 900px) {
  .about-row {
    flex-direction: column;
  }
  #about-text {
    width: 100%;
    max-width: 530px;
  }
  #about-img-box {
    margin: 60px 0 0 0;
  }
}
