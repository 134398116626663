header {
  width: 83%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(80vh);
}

h1 {
  color: white;
  font-size: 3.75rem;
  line-height: 4rem;
  margin: 0;
}

p {
  color: white;
  font-size: 1.3rem;
  margin: 0;
  width: 100%;
  max-width: 500px;
  line-height: 30px;
  margin: 1em 0 2.5em 0;
}

#view-proj-btn {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: none;
  margin-right: 0;
  border-radius: 0.3125rem;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 1px 27px 0 27px;
  width: 184px;
  height: 38px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.2s;
}

#view-proj-btn:hover {
  background-color: rgba(255, 255, 255, 0.314);
  color: white;
  transition: all 0.2s;
}

#view-proj-btn svg {
  position: absolute;
  margin-left: 110px;
  transition: all 0.2s;
}

#view-proj-btn:hover svg {
  fill: white;
  transition: all 0.2s;
  margin-left: 120px;
}

#arrow-right {
  width: 9px;
  margin-left: 10px;
}

#scroll-down-arrow {
  rotate: 180deg;
  transform: scale(1.7, 1);
  font-weight: 100;
  position: absolute;
  left: 50%;
  color: white;
  font-size: 40px;
  animation: bob 2s infinite;
  cursor: pointer;
}

@keyframes bob {
  0% {
    bottom: 1.5vh;
  }
  50% {
    bottom: 2vh;
  }
  100% {
    bottom: 1.5vh;
  }
}

@media (max-width: 1140px) {
  h1 {
    font-size: 3.5rem;
  }
}

@media (max-width: 1080px) {
  header {
    width: 600px;
  }
}

@media (max-width: 750px) {
  header {
    width: 83%;
  }
  h1 {
    font-size: 2.8rem;
    line-height: 3.5rem;
  }
  p {
    font-size: 1.1rem;
  }
}

@media (max-width: 500px) {
  h1 {
    font-size: 2.5rem;
    line-height: 50px;
  }
  header {
    width: 90%;
  }
}
