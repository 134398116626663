.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card {
  opacity: 0;
  will-change: transform, opacity;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(48%);
  margin-bottom: 3.2rem;
  background-color: #171717;
  border-radius: 0.3125rem;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s all;
  position: relative;
  will-change: transform, opacity;
  overflow: hidden;
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
}

.card-caption-box {
  padding: 3rem 2rem 1rem 2rem;
}

.img-box {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.img-box img {
  width: 100%;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}

.img-box {
  position: relative;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: 0px 0px 10px rgba(0, 0, 0);
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  opacity: 0;
}

.card-caption1 {
    color: white;
    font-weight: 800;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
}

.card:hover img {
  transition: all .2s;
  filter: grayscale(90%) brightness(20%) blur(0.7rem);
}

.card:hover .overlay-text {
  opacity: 1;
  transition: all 0.2s;
}

#non-click-proj:hover img{
  filter: none;
  overflow: hidden;
}

.card-caption2 {
    color: white;
    font-size: 1rem;
    margin-bottom: 2rem;
    line-height: 1.5rem;
}

.card-caption3 {
    color: white;
    font-size: 1rem;
    margin-bottom: 1rem;
}

@media (max-width: 850px) {
    .card {width: 100%;}
}
