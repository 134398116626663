.nav-bar {
  color: white;
  display: flex;
  height: 90px;
  justify-content: space-between;
  align-items: center;
}

span {
  transition: all 0.3s;
  display: inline-block;
}

ul {
  display: flex;
  list-style: none;
  margin: 0;
}

li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0 20px;
  padding: 0 13px 0 13px;
  height: 38px;
  cursor: pointer;
}

#hover1 {
  transition: all 1s;
  background: linear-gradient(to right, #ffffff, #ffffff) no-repeat bottom;
  background-size: 0 1px;
  transition: background-size 0.2s ease-in-out;
}

#hover1:hover {
  background-size: 100% 1px;
}

#contact-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  border-radius: 0.3125rem;
  background-color: rgba(255, 255, 255, 0.314);
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 0 27px 0 27px;
  transition: all 0.2s;
}

#contact-btn:hover {
  transition: all 0.2s;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.menu-icon {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 23.5px;
  z-index: 30;
  cursor: pointer;
}

.bar {
  width: 35px;
  height: 2.5px;
  border-radius: 1px;
}

.nav-options {
  display: flex;
  align-items: flex-end;
  background-color: rgb(255, 255, 255);
  flex-direction: column;
  width: 100%;
  left: 0;
  position: fixed;
  top: 0px;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 1;
  padding: 0;
}

.nav-options li {
  transition: background-color 0.2s;
  background-color: white;
  font-size: 18px;
  margin-right: 20px;
}

.nav-options li:hover {
  transform: background-color 0.2s;
  background-color: #1e21830d;
}

@media (max-width: 900px) {
  .menu-icon {
    display: flex;
  }
  .nav-options-desktop {
    display: none;
  }
  .nav-options li {
    color: black;
    height: 70px;
    width: 100%;
    justify-content: flex-end;
    padding-right: 100px;
    margin: 0px;
    padding-left: 0;
  }
}

.side-nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 5%;
  top: 40%;
}

@media (max-width: 600px) {
  .nav-options li {
    padding-right: 8%;
  }
}
