html, body {
  overflow-x: hidden;
}

body {
  overflow: hidden;
  max-width: 1140px;
  margin: auto;
  padding: 0 100px 0 100px;
  background: #262a9e;
}

body:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: -webkit-radial-gradient(0% 100%,ellipse cover,rgba(0, 0, 0, 0.4) 10%,rgba(0, 0, 0, 0) 40%),linear-gradient(to bottom,rgba(0, 0, 128, 0.25) 0%,rgba(0, 0, 0, 0.4) 100%),linear-gradient(135deg, #000000 0%, #262a9e 100%);
  background-repeat: repeat-y;
  transform: scaleY(-1);
  transform-origin: center;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #060829;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.dot-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  margin-right: 10px;
  animation: jump 1s infinite;
}

.dot-1 {
  animation-delay: 0.1s;
}

.dot-2 {
  animation-delay: 0.2s;
}

.dot-3 {
  animation-delay: 0.3s;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.circle-box {
  position: absolute;
  overflow: hidden;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: -1;
}

#circle-rotate {
  margin-left: 50vw;
  margin-top: -800px;
  width: 1200px;
  max-width: 1200px;
  min-width: 600px;
  fill: rgb(0, 0, 0);
  animation: rotateCircle 100s linear infinite;
  transform: rotate(270deg);
  position: fixed;
}

@keyframes rotateCircle {
  100% {
    transform: rotate(90deg);
  }
}

.title {
  color: white;
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  body{padding: 0; width: 85%;}
  .circle-box{display: none;}
}

