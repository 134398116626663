.contact-alignment {
    display: flex;
}

.box {
    opacity: 0;
    will-change: transform, opacity;
}

form {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 80%;
}

input, textarea {
    resize: none;
    background-color: rgba(255, 255, 255, 0.185);
    border: none;
    border-radius: 0.3125rem;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

input:focus, textarea:focus {
    outline-offset: 0px !important;
    outline: none !important;
    background-color: rgba(255, 255, 255, 0.314);
}

input {
    height: 25px;
    width: 92%;
    padding: 0.3125rem 4% 5px 4%;
}

.input-title {
    color: white;
    font-size: 14px;
    font-weight:700;
    margin-bottom: 0.5rem;
}

.message-box {
    display: flex;
    flex-direction: column;
    width: 60%;
}

textarea {
    width: 96%;
    height: 60px;
    padding: 10px 2% 10px 2%;
}

.input-header {
    display: flex;
}

.required-error {
    margin-left: 10px;
    color: rgb(220, 39, 39);
    font-size: 14px;
}

#submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin-right: 0;
    border-radius: 0.3125rem;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    text-align: center;
    padding: 0 27px 0 27px;
    width: 184px;
    height: 38px;
    font-size: 16px;
    margin-top: 37px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
}

#submit-button:hover {
    background-color: rgba(255, 255, 255, 0.314);
    color: white;
    transition: all 0.2s;
    /* border: 1px solid white; */
}

.contact-buttons {
    display: flex;
    flex-direction: column;
    width: 10%;
    margin-right: 10%;
    margin-bottom: 65px;
    margin-top: 5px;
    justify-content: space-around;
    align-items: flex-end;
    height: 210px;
}

.contact-buttons a {
    width: 2.2rem;
    height: 2.2rem;
}

.icon {
    position: relative;
    width: 2.2rem;
    opacity: 100%;
    border: 3px solid transparent; /* add transparent border */
    transition: 0.3s all;
    border-radius: 100%;
}

.icon::before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 2.2rem;
    height: 2.2rem;
    border: 3px solid transparent;
    border-radius: 100%;
    transition: transform 0.3s;
  }
  
  .icon:hover::before {
    transform: rotate(360deg);
    border: white outset 3px;
    opacity: 50%;
  }

@media (max-width: 1000px) {
    .contact-alignment {flex-direction: column;}
    form {width: 100%;}
    .contact-buttons {height: 20px; flex-direction: row; justify-content: flex-start; width: auto; margin: 100px 0 0 0;}
    .icon{margin-right: 30px;}
}